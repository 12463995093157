
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                















































.c-btn-icon {
    --color-btn: $color-light;
    --color-hover: $color-light;
    --fill-svg: var(--color-btn);
    --svg-width: 1.313em;
    --wrapped-clip-path: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    pointer-events: none;

    .c-btn:hover & {
        --wrapped-clip-path: 0%;
    }

    &__svg {
        margin-bottom: 0.4em;

        margin-left: var(--svg-spacing);

        &::v-deep svg {
            fill: var(--fill-svg);
        }

        &:nth-child(2) {
            @include transition(all 0.5s ease); // clip path transition isn't a thing
            --fill-svg: var(--color-hover);
            z-index: 2;
            position: absolute;
            clip-path: inset(0 var(--wrapped-clip-path) 0 0);
        }
    }
}
