
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                


















.c-app-footer-bottom-shapes {
    --bs-columns: 6;
    align-self: stretch;
    z-index: -1;

    @media #{md("xs")} {
        height: 0;
        order: 3;
    }

    @media #{md("xs", "max")} {
        margin: var(--grid-gutter-half) var(--grid-gutter-minus) 0 var(--grid-gutter);
    }

    @media (min-width: #{nth(nth($breakpoints, 2), 2)}px) and (max-width: #{nth(nth($breakpoints, 4), 2)}px) {
        // Between sm & lg
        width: calc(50% + var(--grid-gutter-half) + var(--grid-gutter));
        position: absolute;
        top: 0;
        right: var(--grid-gutter-minus);
    }

    .c-shapes-square {
        @include transform(translate(100px, 100px));
        z-index: 2;

        @media #{md("md")} {
            margin-top: calc(var(--copy-padding-top) * -1);
        }
    }

    &__large {
        --aspect-ratio-square: 1;
        // @include transform(translate(0px, 200px));
        margin-top: var(--grid-gutter-2X);
        background-color: var(--color-light);
        aspect-ratio: var(--aspect-ratio-square);

        @media #{md("md")} {
            margin-top: calc((var(--copy-padding-top) - var(--grid-gutter-2X)) * -1);
        }

        @media #{md("xs", "max")} {
            --aspect-ratio-square: 1/0.8;
        }
    }
}
