
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                
























































.c-hero-visual {
    --intersection-x: 50%;
    --intersection-y: 50%;

    align-self: flex-start;
    margin-left: var(--grid-gutter);
    margin-right: calc(var(--wrap-spacer) * -1);

    @media #{md("xs")} {
        order: 2;
        margin-left: var(--grid-gutter-minus);
    }
    @media #{md("sm")} {
        margin-top: 6.5rem;
    }
    @media #{md("lg")} {
        margin-top: 0;
    }

    // Header types
    &--stairs {
        --intersection-x: 0%;
        --intersection-y: 83%;
    }

    &--triangles {
        --intersection-x: 28%;
        --intersection-y: 65%;
        @media #{md("xs")} {
            --intersection-y: 61.5%;
        }
    }

    &--cubes {
        --intersection-x: 8%;
        --intersection-y: 63%;
        @media #{md("xs")} {
            --intersection-y: 56.5%;
        }
    }

    &--columns {
        --intersection-x: 0%;
        --intersection-y: 60%;
        @media #{md("xs")} {
            --intersection-y: 50%;
        }
    }

    &--rectangles {
        --intersection-x: 0%;
        --intersection-y: 60%;
    }
}
