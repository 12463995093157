
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                
































































































.b-scroll-hack-trigger-item {
    --margin-bottom-trigger: var(--grid-gutter-3X);
    display: flex;
    flex-direction: column;
    margin-bottom: var(--margin-bottom-trigger);

    &:nth-child(1n),
    &:nth-child(5n) {
        --scroll-hack-trigger-square-padding: 0rem 8rem 8rem 0rem;
    }
    &:nth-child(2n) {
        --scroll-hack-trigger-square-padding: 8rem 0rem 0rem 8rem;
    }
    &:nth-child(3n) {
        --scroll-hack-trigger-square-padding: 0rem 0rem 8rem 8rem;
    }
    &:nth-child(4n) {
        --scroll-hack-trigger-square-padding: 8rem 8rem 0rem 0rem;
    }
    &:last-child {
        --margin-bottom-trigger: 0px;
    }
    &__inner {
        @include reset-list;
        display: flex;
        flex-direction: column;
        &__square {
            margin: 0px var(--grid-gutter) var(--grid-gutter-2X) 0px;
            padding: var(--scroll-hack-trigger-square-padding);
        }
    }
}
