
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                


































































































































.o-input-text {
    color: var(--input-color-text);

    &.-date {
        display: flex;

        .o-input-text__input {
            flex-grow: 1;
            width: auto;
            padding-top: 0;
            text-transform: uppercase;
        }

        .o-input-text__label {
            position: relative;
            padding-right: 3em;
            padding-left: 0;
            order: -1;
            white-space: nowrap;
        }
    }

    &.is-filled {
        &:not(.-date) .o-input-text__text {
            transform: scale(0.6) translate(0, -50%);
        }
    }

    &.is-disabled {
        pointer-events: none;
        .o-input-text__label {
            color: var(--input-disabled-color-text);
        }
        .o-input-text__input {
            color: var(--input-disabled-color-text);
            background-color: var(--input-disabled-color-bg);
        }
    }
}

.o-input-text__input {
    width: 100%;
    height: var(--input-height);
    padding-right: 0.8em;
    padding-left: 0em;
    padding-top: 1em;
    background: none;
    border-bottom: 1px solid var(--input-border-color);

    @at-root textarea#{&} {
        height: calc(var(--input-height) * 5);
        padding-top: 1.6em;
        padding-bottom: 0.9em;
        resize: none;
    }
}

.o-input-text__label {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: var(--input-height);
    padding-left: 0em;
    pointer-events: none;
}

.o-input-text__text {
    display: inline-block;
    line-height: 1;
    transform-origin: 0 0;
    transition: transform 0.2s ease-out;
}
