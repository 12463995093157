/*=======================================
=            Global settings            =
=======================================*/

// Colors
// https://chir.ag/projects/name-that-color/
$color-dark: #0c162a;
$color-light: #ffffff;
$color-malibu: #69c1ff;
$color-gray-chateau: #9da4b3;
$color-concrete: #f2f2f2;

$color-dark-rgba: rgba(12, 22, 42, 0.4);

$font-color: $color-dark;
$selection-bg: $color-dark;
$selection-color: $color-light;

// Typography
$font-families: (
    "heading": (
        "Apfel Grotezk",
        "Helvetica Neue",
        Arial,
        sans-serif
    ),
    "body": (
        "Founders Grotesk",
        "Helvetica Neue",
        Arial,
        sans-serif
    )
);

$font-size: 10px;
$line-height: 1.45;

$font-sizes: (
    "small": 1.2rem,
    "regular": 1.4rem,
    "medium": 1.6rem,
    "large": 1.8rem,
    "xlarge": 2rem
);

// Fontfaces
$font-dir: "/static/fonts/";

$fontfaces: (
    "Apfel Grotezk" "ApfelGrotezk-Regular" 600 normal,
    "Founders Grotesk" "FoundersGrotesk-Regular" 400 normal,
    "Founders Grotesk" "FoundersGrotesk-Medium" 600 normal
);

// Accessibility
$accessible-size: 44px;

$border-radius-xs: 0.3rem;
$border-radius-sm: 0.625em;
$border-radius-md: 1.25em;
$border-radius-lg: 3.75em;

/*=================================================
=            Media queries breakpoints            =
=================================================*/

// $grid-breakpoints: $grid-gutters;
$grid-breakpoints: (
    xs: 0,
    sm: 480px,
    md: 768px,
    lg: 992px,
    xl: 1280px,
    xxl: 1360px,
    xxxl: 1920px
) !default;

// the new grid was with a support for the previous grid. The grid points below should be remove in the future
$breakpoints: (
    "xxs": 0,
    "xs": 480,
    "sm": 768,
    "md": 992,
    "lg": 1280,
    "xl": 1440,
    "xxl": 1600,
    "xxxl": 1920
);

/*============================
=            Grid            =
============================*/

$grid-width: 170rem; // 128rem;
$grid-columns: 16;
$grid-gutter: 2rem;
$grid-gutters: (
    "xxs": 2rem,
    "xs": 2rem,
    "sm": 2rem,
    "md": 2rem,
    "lg": 2rem,
    "xl": 2rem,
    "xxl": 2rem,
    "xxxl": 2rem
);

$enable-grid-classes: true;
$grid-gutter-width: 1.5rem !default;
$prefix: bs- !default;
$enable-cssgrid: true;

/*===========================================
=            Images and SVG dirs            =
===========================================*/

$img-dir: "/img/layout/";
$svg-dir: "/assets/svg/layout/";

/*=================================
=            Z-indexes            =
=================================*/

$layers: (
    "goku": 9000,
    "modal": 200,
    "dropdown": 100,
    "default": 1,
    "limbo": -999
);

/*==============================
=            Easing            =
==============================*/

$in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

$in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);

$in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);

$in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

$in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

$in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$in-out-expo: cubic-bezier(1, 0, 0, 1);

$in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);

$in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$out-back: cubic-bezier(0.175, 00.885, 0.32, 1.275);
$in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

$out-back-t: cubic-bezier(0.535, 1.65, 0.635, 1.005);
