
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                







































.c-app-footer-bottom {
    --copy-padding-top: 19rem;
    --copy-padding-bottom: 7rem;
    @include darkBackgroundTextSelect;
    // @include pre-animation(0px, 100px);
    color: var(--color-gray-chateau);
    padding-top: var(--copy-padding-top);
    padding-bottom: var(--copy-padding-bottom);
    align-items: center;
    font-size: fs("regular");
    line-height: 1;
    overflow: hidden;
    z-index: 1;

    @media #{md("xs", "max")} {
        --bs-row-gap: var(--grid-gutter-1-5X);
        --copy-padding-top: var(--grid-gutter-4X);
        --copy-padding-bottom: 0;
        justify-items: center;

        > * {
            grid-column: 1 / -1;
        }
    }

    &__logo {
        --svg-width: 3.7rem;
        --svg-ratio: 1.243;

        &::v-deep svg {
            fill: var(--color-light);
        }
    }

    &__copy {
        @include transform(translate(0px, 10px));
        display: flex;
        align-items: center;
        white-space: nowrap;

        &__line {
            width: var(--grid-gutter-3X);
            height: 1px;
            margin: 1px var(--grid-gutter-half) 0;
            background-color: var(--color-gray-chateau);
        }
    }

    &__credits {
        @include transform(translate(0px, 10px));
        @media #{md("xs")} {
            justify-self: end;
            order: 4;
            z-index: 9;
        }
    }
}
