
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                





























.b-approach__elements {
    --content-gap: var(--grid-gutter-2X);
    @include reset-list;
    counter-set: elements
}

.-element {
    --content-gap: .8rem;
    counter-increment: elements
}

.-title {
    &:before {
        @include fontSmallCopy();
        color: var(--color-gray-chateau);
        content: counter(elements, decimal-leading-zero) ".";
    }
}

.-content {
    color: var(--color-gray-chateau);
}
