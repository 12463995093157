
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                



















































































































































































































































.c-form {
    --input-height: 4rem;

    --input-border-color: #{$color-dark};
    --input-border-radius: #{$border-radius-xs};

    --input-color-text: #{$color-dark};
    --input-color-bg: transparent;

    --input-disabled-color-text: $color-light;
    --input-disabled-color-bg: #{$color-light};

    &.is-sending {
        cursor: wait;
        pointer-events: none;
        opacity: 0.8;
    }
}

.c-form__error {
    padding: 1em;
    margin-bottom: 1em;
    background: $color-dark;
    color: $color-light;
}

.c-form__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 2;
    > div {
        @include pre-animation(0px, 30px);
    }
}

.c-form__heading {
    width: 100%;
    margin-bottom: 0.75em;

    &:not(:first-child) {
        margin-top: 2em;
    }
}

.c-form__el {
    // @include pre-animation(0px, 100px);
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    margin-bottom: var(--grid-gutter);

    &.has-error {
        --input-border-color: red;
    }

    &.-submit {
        display: flex;
        padding-top: var(--grid-gutter-2X);
        margin-bottom: 0px;
    }

    @media #{md("md")} {
        &.-half {
            width: calc(50% - var(--grid-gutter-half));
        }
    }
}

.c-form__success {
    h4 {
        @include t4;
    }
}
