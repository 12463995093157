
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                







































$transition-approach: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s;
.b-approach {
    --b-safe-space: var(--grid-gutter-2X);
    --approach-opacity: 0;
    --translate-x-left-approach: -100px;
    --translate-x-right-approach: 100px;
    @include darkBackgroundTextSelect;
    @include transition(opacity 0.5s ease);
    color: var(--color-light);
    background-color: var(--color-dark);
    padding-top: var(--b-safe-space);

    opacity: var(--approach-opacity);

    @media #{md("lg")} {
        --bs-rows: 3;
        --b-safe-space: var(--grid-gutter-4X);
    }

    &.is-visible {
        --approach-opacity: 1;
        --translate-x-left-approach: 0px;
        --translate-x-right-approach: 0px;
    }

    .-heading {
        @include transition($transition-approach);
        @include transform(translateX(var(--translate-x-left-approach)));
        padding-bottom: var(--grid-gutter);
        opacity: var(--approach-opacity);
    }

    .-elements {
        @include transition($transition-approach);
        @include transform(translateX(var(--translate-x-right-approach)));
        grid-row: span 3;
        padding-bottom: var(--b-safe-space);
        opacity: var(--approach-opacity);
    }
    .-image {
        @include transition($transition-approach);
        @include transform(translateX(var(--translate-x-left-approach)));
        grid-row: span 2;

        display: flex;
        align-items: flex-end;
        opacity: var(--approach-opacity);
        @media #{md("md")} {
            margin-left: calc(var(--grid-gutter-2X) * -1);
        }
    }
}
