
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                














































































































































































.b-scroll-hack-main-square-item {
    --top: auto;
    --right: auto;
    --bottom: auto;
    --left: auto;
    --size-square: 8rem;
    --background-color: var(--color-dark); // backup
    @include reset-list;
    position: absolute;
    width: var(--size-square);
    height: var(--size-square); // aspect-ratio: 1/1; https://mambomambo-team.atlassian.net/browse/QUAD-251
    top: var(--top);
    right: var(--right);
    bottom: var(--bottom);
    left: var(--left);

    background-color: var(--background-color);

    @media #{md("lg")} {
        --size-square: 11rem;
    }

    &__texture {
        display: flex;
        flex: 1;
        width: 100%;
        height: 100%;
    }
}
