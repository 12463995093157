
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                












































































.b-scrollhack-main-list {
    --opacity: 0;
    --translate: 10rem;
    --scroll: 0vh;
    --height: 10rem;
    --scroll-hack-min-height: 35rem;
    @mixin gradientScrollHack($rotation: 180deg, $height: 10rem) {
        content: " ";
        position: absolute;
        width: 100%;
        height: $height;
        background: rgb(255, 255, 255);
        background: linear-gradient($rotation, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        z-index: 1;
    }
    $animation-scroll-hack-main-list: 0.3s ease opacity, 0.3s ease transform;
    @include transition($animation-scroll-hack-main-list);
    @include transform(translateY(var(--translate)));

    display: flex;
    flex-direction: column;
    height: calc(100% + var(--height) * 2); // height + the size of the gradients area
    min-height: calc(var(--scroll-hack-min-height) + var(--height));
    margin-top: calc(0px - var(--height)); // ensure that the gradient isn't on the top of the copy by default

    opacity: var(--opacity);
    overflow: hidden;

    &--visible {
        --opacity: 1;
        --translate: 0rem;
    }

    &:before {
        @include gradientScrollHack(180deg, var(--height));
        top: 0px;
        left: 0px;
    }
    &:after {
        @include gradientScrollHack(0deg, var(--height));
        top: auto;
        bottom: 0px;
        left: 0px;
    }
    &__list {
        @include transition(1s ease all);
        @include reset-list;
        counter-reset: scroll-hack-counter;
        position: absolute;
        top: calc(0px - var(--scroll));
    }
}
