
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                




















































































































































































































































































































































































































































































































.o-loader {
    z-index: 102;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 1;
    background: none;

    &__bg {
        --clip-path-1: 1%;
        --clip-path-2: 99%;

        --clip-path-in-1: 100%;
        --clip-path-in-2: 0%;
        --clip-path-in-3: 100%;
        --clip-path-in-4: 0%;

        --clip-path: 0 0, var(--clip-path-in-1) var(--clip-path-in-2), var(--clip-path-in-3) var(--clip-path-in-4),
            100% 100%, 100% 100%, var(--clip-path-1) 100%, 0 var(--clip-path-2), 0 0; //0 0, 100% 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 100%, 0 0
        clip-path: polygon(var(--clip-path));

        background: var(--color-light);
        position: absolute;
        min-width: 100vw;
        min-height: 100vh;
    }
}
