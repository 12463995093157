
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                


























































































































.c-btn {
    --color-btn: $color-light;
    --color-line: $color-light;
    --hover-color: #69c1ff;
    --line-display: none;
    --svg-spacing: 0.625em;

    --background-position: 100%;

    @include disabler;
    @include transition(opacity 0.5s ease);
    @include underline(var(--line-display), var(--color-line), var(--hover-color), 0em, var(--background-position));
    display: inline-flex;
    align-items: center;
    padding: 0.5em 0em;
    overflow: hidden;

    cursor: pointer;

    &:hover {
        --background-position: 0%;
    }

    &--underline {
        --line-display: inline-block;
    }

    &__label {
        @include transition(0.3s ease all);
        color: var(--color-btn);
        background-image: linear-gradient(90deg, var(--hover-color) 50%, var(--color-btn) 50%);
        background-clip: border-box;
        background-size: 200% 100px;
        background-position: var(--background-position);
        background-clip: text;
        -webkit-background-clip: text;
        text-fill-color: transparent;
        -webkit-text-fill-color: transparent;

        display: inline-block;

        & + .c-btn__icon {
            margin-left: var(--svg-spacing);
        }
    }
}
