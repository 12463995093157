
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                

































.b-steps-card-hover {
    --action-card-height: 100%;
    @include full-screen-dom(absolute);
    @include transition(clip-path ease 0.8s);
    display: flex;
    background-color: var(--color-malibu);
    clip-path: inset(0 0 var(--action-card-height) 0);
    z-index: 9;
}
