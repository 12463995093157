
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                




















































.c-hero {
    --hero-margin-bottom: var(--grid-gutter-3X);
    margin-bottom: var(--hero-margin-bottom);
    overflow: hidden;

    @media #{md("md")} {
        --hero-margin-bottom: var(--grid-gutter-5X);
    }

    .p-home & {
        @media #{md("sm")} {
            --hero-margin-bottom: 16rem;
        }
    }

    &__inner {
        --spacing-hero: var(--grid-gutter-4X);
        --margin-bottom-hero: var(--grid-gutter-2X);
        --bs-row-gap: var(--spacing-hero);
        --min-height-hero: 20rem;
        margin-bottom: var(--margin-bottom-hero);

        min-height: var(--min-height-hero);

        @media #{md("sm")} {
            --min-height-hero: 60rem;
            --margin-bottom-hero: var(--spacing-hero);
        }
    }
}
