
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                








































.b-scrollhack-main-square-icon {
    @include full-screen-dom(absolute);

    padding-bottom: 100%; // aspect-ratio: 1/1; // https://mambomambo-team.atlassian.net/browse/QUAD-251
    height: 100%;
    z-index: 1;

    &__list {
        @include reset-list;
        position: relative;
        margin-top: 50%;
        z-index: 10;
    }
}
