
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                





















.b-tagline {
    $transition-tagline: 0.5s ease transform, 0.3s linear opacity;
    --opacity-timeline: 0;
    --translate-timeline: -100px;
    &.is-visible {
        --opacity-timeline: 1;
        --translate-timeline: 0px;
    }
    &__inner {
        @include transition($transition-tagline);
        @include transform(translateX(var(--translate-timeline)));
        opacity: var(--opacity-timeline);
        --text-large-align: left;
        padding: var(--grid-gutter) 0;
        text-align: var(--text-large-align);

        @media #{md("md")} {
            --text-large-align: center;
        }
        h2 {
            @include b-for-blue;
            @include t2;

            @media #{md("md")} {
                margin-left: var(--grid-gutter-minus);
                margin-right: var(--grid-gutter-minus);
            }
        }
    }
}
