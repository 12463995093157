
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                



































.p-home {
    @include transition(0.5s ease opacity);
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}
