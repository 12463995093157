
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                
















































































.b-articles-slide {
    order: var(--article-order-slider);

    &__slide {
        display: flex;
        flex-direction: column-reverse;

        .c-slider__slide {
            @include pre-animation(0px, 100px); // animation
        }

        .flickity-viewport {
            overflow: visible;
        }
    }
}
