
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                















































































































































.block-highlight {
    --highlight-v-space: var(--block-spacer);
    --highlight-bottom-space: 4rem;
    padding-top: var(--highlight-v-space);
    padding-bottom: calc(var(--highlight-v-space) + var(--highlight-bottom-space));
    overflow: hidden;
    background-color: var(--color-dark);

    // Fake negative margin bottom
    > * {
        z-index: 2;
    }

    &:after {
        content: "";
        height: var(--highlight-bottom-space);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background: white;
    }
}

.b-highlight {
    &__copy {
        @include pre-animation(-100px, 0px);
        .t-cms {
            color: var(--color-light);

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: var(--color-malibu);
            }
        }
    }

    &__visual {
        align-self: end;
        margin-bottom: calc((var(--highlight-v-space) + var(--highlight-bottom-space)) * -1);

        @media #{md("xs")} {
            margin-top: calc((var(--highlight-v-space) + var(--bs-row-gap)) * -1);
        }

        @media #{md("md")} {
            margin-top: 0;
        }

        .c-shapes-square {
            --width-shape-square: 12rem;
            position: relative;
            margin-left: auto;

            @media #{md("xs", "max")} {
                margin-right: var(--grid-gutter-minus-2X);
                margin-bottom: calc(var(--width-shape-square) * -0.5);
            }

            @media #{md("xs")} {
                --width-shape-square: 16rem;
                transform: translateX(100%);
            }
        }

        &__square {
            @include transform(translate(100px, 100px));
        }

        &__img {
            --highlight-img-ratio: 1 / 0.8;
            @include transform(translate(-100px, 100px));
            aspect-ratio: var(--highlight-img-ratio);
            background: var(--color-malibu);

            @media #{md("xs", "max")} {
                margin-left: var(--grid-gutter-minus);
            }

            .o-asset {
                aspect-ratio: var(--highlight-img-ratio);
            }
        }
    }
}
