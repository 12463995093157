
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                











































.b-gallery {
    display: flex;
    flex-direction: column-reverse;

    .c-slider__side {
        justify-content: flex-end;
        margin: var(--grid-gutter-half) var(--grid-gutter) 0;
    }

    .c-slider__btn,
    .c-slide__count {
        --nav-bg: #{$color-light};
    }

}
