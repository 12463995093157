
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                







































.p-error {
    --min-height: 100vh;
    width: 100vw;
    height: var(--min-height);
    @media #{md("md")} {
        --min-height: auto;
    }
}
