
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                



















.c-app-nav {
    --position-nav: static;
    display: flex;
    flex-direction: column;
    position: var(--position-nav);
    @media #{md("sm")} {
        --position-nav: relative;
    }
}
