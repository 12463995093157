
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                






















































.c-app-header-logo {
    @include transition(all 0.5s ease);
    --header-logo-width: 0px;
    --svg-width: 150px;
    --svg-ratio: 0.253;
    --header-logo-right: 0;

    display: flex;
    margin-right: var(--header-logo-right);
    overflow: hidden;
    width: var(--header-logo-width);
    z-index: 1;

    &--visible {
        --header-logo-right: var(--grid-gutter-2X);
        --header-logo-width: var(--svg-width);

        @media #{md("xxl")} {
            --header-logo-right: var(--grid-gutter-3X);
        }
    }

    a {
        display: flex;
        align-items: center;
    }

    svg {
        width: var(--svg-width, 1em);
        height: calc(var(--svg-width, 1em) * var(--svg-ratio, 1));

        @media #{md("sm", "max")} {
            .logo-square {
                transform: translate(-80%, 5%);
                transition: transform 0.4s $in-out-cubic;
            }

            .logo-letter:not(.-letter-q) {
                transform: translateY(100%);
                transition: transform 0.35s $in-out-cubic;

                @for $i from 1 through 7 {
                    &:nth-child(#{$i}) {
                        transition-delay: #{$i * 0.02s};
                    }
                }
            }

            .l-header--menu-mobile-open & {
                .logo-square {
                    transform: translateX(0);
                }

                .logo-letter:not(.-letter-q) {
                    transform: translateY(0);

                    @for $i from 1 through 7 {
                        &:nth-child(#{$i}) {
                            transition-delay: calc(0.2s - #{$i * 0.02s});
                        }
                    }
                }
            }
        }
    }
}
