
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                








































































































































.c-card-grid {
    $self: &;
    --grid-auto-size: auto-fill;
    --grid-min-width: 18em;
    --grid-custom-gap: var(--elements-grid-gap);

    &.-solution {
        --grid-auto-size: max-content;
    }

    &.-no-slider {
        .c-card-grid__grid {
            display: grid;
            grid-gap: var(--grid-custom-gap);
            grid-template-columns: repeat(var(--grid-auto-size), minmax(var(--grid-min-width), 1fr));
            justify-content: center;
            overflow-anchor: none;
        }

        .c-card {
            --card-width: 100%;
        }
    }

    &.-slider {
        .c-slider {
            --slide-width: 18em;
        }

        &.-solution {
            .c-slider {
                --slide-width: calc(100%);
                --nav-bg: #{$color-light};

                @media #{md("sm")} {
                    --slide-width: calc(3 / 4 * 100%);
                }

                @media #{md("md")} {
                    --slide-width: calc(11 / 12 * 100%);
                }

                @media #{md("lg")} {
                    --slide-width: calc(3 / 4 * 100%);
                }

                @media #{md("xl")} {
                    --slide-width: 62rem;
                }
            }

            .c-btn.-underline {
                --btn-hover-bg-color: #{$color-light};
            }

            @media #{md("sm", "max")} {
                .c-slider__side {
                    flex-direction: column;
                    align-items: flex-start;

                    .c-slider__afterNav {
                        position: relative;
                        padding-top: var(--grid-gutter);
                    }
                    .c-slide__count {
                        display: none;
                    }

                    .c-slider__nav {
                        position: absolute;
                        bottom: 0;
                        overflow: visible;
                        left: 0;
                        right: 0;
                        transform: translateY(300px);
                        z-index: 1;
                        pointer-events: none;

                        .-prev {
                            border: 0;
                            pointer-events: auto;
                            transform: translateX(calc(var(--grid-gutter-half) / 2 * -1));
                        }
                        .-next {
                            border: 0;
                            transform: translateX(calc(var(--grid-gutter-half) / 2));
                            position: absolute;
                            right: 0;
                            pointer-events: auto;
                        }
                    }
                }
            }
        }

        .c-slider__inner:after {
            content: "flickity";
            display: none;
        }

        .c-card {
            width: 100%;

            &:not(:first-child) {
                margin-left: 1em;
            }
        }
    }
}
