/*=================================
=            Selection            =
=================================*/

::-moz-selection {
    text-shadow: none;
    color: $selection-color;
    background: $selection-bg;
}

::selection {
    text-shadow: none;
    color: $selection-color;
    background: $selection-bg;
}

/*=======================================
=            Global settings            =
=======================================*/

:root {
    // Colors
    --color-dark: #{$color-dark};
    --color-dark-lighten: #{$color-dark-rgba};
    --color-dark: #{$color-dark};
    --color-light: #{$color-light};
    --color-malibu: #{$color-malibu};
    --color-gray-chateau: #{$color-gray-chateau};
    --color-concrete: #{$color-concrete};

    // Grid
    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);

        @media #{md($key)} {
            --grid-gutter: #{map-get($grid-gutters, $key)};
            --grid-gutter-half: calc(var(--grid-gutter) / 2);
            --grid-gutter-third: calc(var(--grid-gutter) / 3);
            --grid-gutter-quarter: calc(var(--grid-gutter) / 4);
            --grid-gutter-1-25X: calc(var(--grid-gutter) * 1.25);
            --grid-gutter-1-5X: calc(var(--grid-gutter) * 1.5);
            --grid-gutter-2X: calc(var(--grid-gutter) * 2);
            --grid-gutter-3X: calc(var(--grid-gutter) * 3);
            --grid-gutter-4X: calc(var(--grid-gutter) * 4);
            --grid-gutter-5X: calc(var(--grid-gutter) * 5);
            --grid-gutter-6X: calc(var(--grid-gutter) * 6);
            --grid-gutter-minus: calc(0px - var(--grid-gutter));
            --grid-gutter-minus-0-5: calc(0px - var(--grid-gutter-half));
            --grid-gutter-minus-2X: calc(0px - var(--grid-gutter-2X));
            --grid-gutter-minus-4X: calc(0px - var(--grid-gutter-4X));
        }
    }

    --bs-col-gap: var(--grid-gutter);
    --bs-row-gap: var(--grid-gutter-3X);
    --bs-columns: 6;

    @media #{md("xs")} {
        --bs-columns: 12;
    }

    @media #{md("md")} {
        --bs-columns: #{$grid-columns};
    }
}

*,
*:after,
*:before {
    position: relative;
    outline: none;
}

html {
    font-size: $font-size;

    // Better font rendering for mac
    &.is-mac-os,
    &.is-ios {
        @include antialiased;
    }
}
