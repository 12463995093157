
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                




















































.c-card-content {
    --card-color: var(--color-dark);
    @include reset-list;
    @include t-content;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--grid-gutter);
    color: var(--card-color);
    &--white {
        --card-color: var(--color-light);
    }
    h3 {
        @include t3;
    }
}
