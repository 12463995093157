
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                



































































.b-scrollhack-main-square {
    --opacity: 0;
    --square-color: var(--color-concrete);
    @include transition(0.5s ease opacity);
    display: flex;
    justify-content: center;
    align-content: center;
    opacity: var(--opacity);

    height: 0%;
    // padding-bottom:100%; // friendly to browsers without aspect ratio

    &:after {
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        content: " ";
        background: var(--square-color);
        padding-bottom: 100%;
        // height: 100%;
    }

    &--visible {
        --opacity: 1;
    }
    &--blue {
        --square-color: var(--color-malibu);
    }

    &__list {
        @include reset-list;
        display: flex;
        position: relative;
        width: 100%;
        padding-bottom: 100%; // aspect-ratio: 1/1; https://mambomambo-team.atlassian.net/browse/QUAD-251
        // @media #{md("sm")} {
        // }
    }
}
