
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                






































.c-app-footer-top {
    --bs-row-gap: var(--grid-gutter-3X);
    align-items: center;
}
