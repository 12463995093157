
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                













































































































































































































.b-articles {
    --article-order-title: 1;
    --article-order-slider: 3;
    --article-order-btn: 2;
    --article-order-arrows: 4;

    @media #{md("xs")} {
        --article-order-slider: 2;
        --article-order-btn: 3;
    }

    &__slider {
        row-gap: 0;

        &__arrows {
            @include pre-animation(0px, 100px); // animation
            order: var(--article-order-arrows);
        }

        &__btn {
            --grid-row-btn: 1;
            @include pre-animation(-100px, 100px); // animation

            margin-right: calc(0px - var(--grid-gutter));
            order: var(--article-order-btn);
            // grid-row: var(--grid-row-btn);

            @media #{md("sm")} {
                --grid-row-btn: 2;
            }
        }
    }
}
