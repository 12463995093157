
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                

























































































































.c-pagination {
    --elements-gap: var(--grid-gutter-3X);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: var(--elements-gap);

    @media #{md("lg")} {
        --elements-gap: var(--grid-gutter-4X);
    }

    &__progress {
        color: var(--color-gray-chateau);
        padding: 0 var(--grid-gutter) 0.6rem;

        .-load-progress {
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: var(--color-gray-chateau);

            &:before {
                @include pseudo-el($width: 100%, $height: 100%, $bg: var(--color-dark));
                position: absolute;
                left: 0;
                transform-origin: left;
                transform: scaleX(var(--progress-perc));
                transition: scaleX 0.5s ease-out;
            }
        }
    }

    .c-shared-btn-square {
        width: 22rem;
        margin-top: var(--grid-gutter-2X);

        &--disabled {
            pointer-events: none;
        }
    }
}
