
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                























































































.c-link {
    --color-btn: var(--color-gray-chateau);

    @include disabler;
    @include transition(color 0.5s ease);
    display: inline-flex;
    align-items: center;
    color: var(--color-btn);
    padding-bottom: 1.1rem;
    cursor: pointer;

    &__label {
        @include t3;
        line-height: 0.55;
    }

    &__count {
        line-height: 1;
        top: -0.1rem;
        margin-left: 0.6rem;
    }

    &:after {
        @include pseudo-el;
        @include transition(all 0.4s $in-out-quad);
        --line-opacity: 0;
        --line-translateY: -0.4rem;
        height: 2px;
        position: absolute;
        top: 100%;
        left: 0;
        opacity: var(--line-opacity);
        background: var(--color-btn);
        transform: translateY(var(--line-translateY));
    }

    // Active
    &:hover,
    &--active {
        --color-btn: var(--color-dark);

        &:after {
            --line-opacity: 1;
            --line-translateY: 0;
        }
    }
}
