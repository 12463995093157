
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                




































































































































































.l-elements {
    &__filters {
        width: 100%;
        justify-self: center;
        align-items: center;
        z-index: 5;

        .o-input {
            opacity: 0;
            width: 0;
        }
    }

    &__filter-group {
        --items-spacing: var(--grid-gutter);
        display: flex;
        align-items: flex-start;
        transition: transform 0.35s $in-out-quad, opacity 0.5s $out-quad;

        @media #{md("sm")} {
            --items-spacing: var(--grid-gutter-2X);
        }

        @media #{md("lg")} {
            --items-spacing: var(--grid-gutter-3X);
        }

        > *:nth-child(n + 2) {
            margin-left: var(--items-spacing);
        }
    }

    &__filter-items {
        @include pre-animation(0px, 100px); // animation
        display: flex;
        align-items: flex-start;
    }
}
