
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                



























































.b-content {
    @include pre-animation(0px, 100px);
    &.is-visible {
        @include transform(translate(0px, 0px));
        opacity: 1;
        transition: 0.5s ease transform, 0.3s linear opacity;
    }
    &__inner {
        row-gap: $line-height * 1em;
        align-items: var(--valign, start);
    }

    &__column {
        --only-column: 1 / -1;
        --left-column: var(--only-column);
        --right-column: var(--only-column);

        figure {
            --figure-width: 100%;
            --figure-margin-left: 0px;
            width: var(--figure-width);
            margin-left: var(--figure-margin-left);
            @media #{md("xs")} {
                --figure-width: 100%;
                --figure-margin-left: 0px;
            }
        }

        &:nth-child(2n -1) {
            grid-column: var(--left-column);
        }
        &:nth-child(2n) {
            grid-column: var(--right-column);
        }
        &:only-child {
            grid-column: var(--only-column);
        }

        @media #{md("xs")} {
            --only-column: 2 / span 10;
        }

        @media #{md("md")} {
            --only-column: 5 / span 8;
            --left-column: 1 / span 7;
            --right-column: 9 / span 7;
        }
    }
}
