
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                







































































.c-card {
    --card-aspect-ratio: 1/1.3;
    --card-width: 33.5rem;
    width: var(--card-width);
    position: relative;
    aspect-ratio: var(--card-aspect-ratio);
    padding: var(--grid-gutter);
    background: var(--color-concrete);

    @media #{md("lg")} {
        --card-width: 38.65rem;
    }

    &__img {
        @include full-screen-dom(absolute);
        z-index: 0;
        .o-asset {
            display: flex;
            flex: 1;
            height: 100%;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        z-index: 1;
    }
}
