
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                





























































































































.b-steps-grid {
    @include reset-list;
    --actions-grid-margin: var(--grid-gutter-2X);
    --actions-rows: repeat(4, 19rem);
    counter-reset: steps;
    row-gap: 0;
    grid-template-rows: var(--actions-rows);
    margin-top: var(--actions-grid-margin);

    @media #{md("xs")} {
        --actions-grid-margin: var(--grid-gutter-4X);
        --actions-rows: 9.6rem 12.5rem 17rem 14rem;
    }

    &__btn {
        --action-cta-column: auto / span 4;
        --action-cta-row-start: auto;
        --action-cta-translate-x: var(--grid-gutter-minus);
        --action-cta-translate-y: 0;
        @include transform(translate(-100px, -100%));
        // @include transform(translate(-100px, 100px));
        grid-column: var(--action-cta-column);
        grid-row-start: var(--action-cta-row-start);
        margin-left: var(--grid-gutter-minus);
        margin-top: 3px;
        // opacity: 0;
        // transform: translate(var(--action-cta-translate-x), var(--action-cta-translate-y));
        // margin-left:calc(

        @media #{md("xs")} {
            --action-cta-column: span 3 / -1;
            --action-cta-row-start: 2;
            --action-cta-translate-x: 0;
            --action-cta-translate-y: -100%;
            @include transform(translate(-100px, -400%));
        }

        @media #{md("md")} {
            // --action-cta-translate-x: var(--grid-gutter-minus);
        }
    }
}
