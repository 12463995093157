
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                





































.b-scroll-hack-trigger {
    &__list {
        @include reset-list;
        display: flex;
        flex-direction: column;
        counter-reset: scroll-hack-counter;
    }
}
