
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                
















.c-app-footer-top-contact {
    --margin-top-form: var(--grid-gutter-4X);
    @include transform(translate(-200px, 200px));
    margin-top: calc(0px - var(--margin-top-form));
    padding: var(--grid-gutter-3X) var(--grid-gutter-1-5X) calc(var(--grid-gutter-1-5X) - 0.5rem);
    background-color: var(--color-malibu);

    @media #{md("xs")} {
        --margin-top-form: var(--grid-gutter-1-5X);
    }

    @media #{md("md")} {
        margin-right: calc(0px - var(--grid-gutter));
        padding: var(--grid-gutter-4X) var(--grid-gutter-2X) calc(var(--grid-gutter-2X) - 0.5rem);
    }
}
