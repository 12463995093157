
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                






























































.s-externals {
    --offset-spacer: var(--grid-gutter-4X);
    --inner-v-padding: var(--grid-gutter-1-5X);
    --inner-h-padding: var(--grid-gutter-1-5X);
    --cover-offset-top: 5rem;
    margin-top: calc(var(--offset-spacer) + var(--cover-offset-top));

    @media #{md("xs")} {
        --inner-v-padding: calc(var(--grid-gutter) * 2.5);
        --inner-h-padding: var(--grid-gutter-2X);
    }

    @media #{md("md")} {
        --inner-h-padding: var(--grid-gutter-3X);
    }

    @media #{md("lg")} {
        --offset-spacer: 15rem;
        --cover-offset-top: 6rem;
    }

    &__inner {
        display: flex;
        align-items: center;
        width: 80rem;
        max-width: 100%;
        padding: var(--inner-v-padding) var(--inner-h-padding);
        margin: 0 auto;
        background: var(--color-concrete);

        @media #{md("xs", "max")} {
            flex-wrap: wrap;
        }
    }

    &__cover {
        --cover-width: 13rem;
        --cover-bottom-margin: var(--inner-v-padding);
        width: var(--cover-width);
        aspect-ratio: 1 / 1.5;
        flex-shrink: 0;
        margin-top: calc((var(--inner-v-padding) + var(--cover-offset-top)) * -1);
        margin-bottom: var(--cover-bottom-margin);
        margin-right: var(--inner-h-padding);
        background: var(--color-malibu);
        border-radius: 0.5rem;
        overflow: hidden;
        box-shadow: 0 0.7rem 3.5rem 0.4rem rgba($color-dark, 0.2);

        @media #{md("xs")} {
            --cover-bottom-margin: var(--grid-gutter-minus);
        }

        @media #{md("sm")} {
            --cover-width: 15rem;
        }
        @media #{md("lg")} {
            --cover-width: 18rem;
        }

        .o-asset {
            width: 100%;
            height: 100%;
        }
    }

    &__copy {
        max-width: 60rem;

        h1 {
            @include t3;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        list-style: none;
        margin-top: var(--grid-gutter);

        li {
            margin-right: var(--grid-gutter-1-5X);
        }

        .c-btn {
            white-space: nowrap;
        }
    }
}
