
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                





































.l-builder-article {
    --block-spacer: var(--grid-gutter-3X);

    @media #{md("lg")} {
        --block-spacer: 11rem;
    }

    .-b {
        &:nth-child(n + 2) {
            margin-top: var(--block-spacer);
        }
    }
}
