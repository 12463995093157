
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                




















.c-cross {
    --cross-color: var(--color-gray-chateau);
    @include transition(transform 0.6s $out-expo);
    @include transform(rotate(var(--cross-rotate, 0deg)));
    width: 2.2rem;
    height: 2.2rem;

    &__line {
        @include transition(transform 0.6s $out-expo);
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 0;
        background-color: var(--cross-color);

        &--vertical {
            @include transform(rotate(var(--cross-rotate-line, 90deg)));
        }
    }

    &--dark {
        --cross-color: var(--color-dark);
    }
}
