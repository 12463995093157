/*=========================================
=            Typographic bases            =
=========================================*/

// Include fontface
@include fontfaces($fontfaces, $font-dir);

body {
    font-family: ff("body");
    color: $font-color;
    font-size: fs("large");
    line-height: 1.2;
}

/*==============================
=            Titles            =
==============================*/

.t-t1 {
    @include t1;
}
.t-t2 {
    @include t2;
}
.t-t3 {
    @include t3;
}
.t-t4 {
    @include t4;
}

.t-content {
    @include t-content;
}

/*==========================================
=            CMS content styles            =
==========================================*/

.t-cms {
    @include t-cms;
}

/*=====================================
=            Miscellaneous            =
=====================================*/

small {
    @include fontSmallCopy;
}

sup {
    vertical-align: super;
    @include fontSmallCopy;
}

a {
    color: inherit;
    text-decoration: none;
}

.no-wrap {
    white-space: nowrap;
}
