
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                









































































































































































































.l-footer {
    background-color: var(--color-dark);
    margin-top: 12.5rem;
}
