
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                



























































































































































































.block-accordions {
    @include wrap;
}

.b-accordions {
    &__head {
        @media #{md("md", "max")} {
            margin-left: var(--grid-gutter-minus);
            margin-right: var(--grid-gutter-minus);
        }

        &__btn {
            --bs-columns: 5;
            --btn-columns: span 3 / -1;

            @include pre-animation(100px, 100px); // animation

            @media #{md("lg")} {
                --bs-columns: 5;
                --btn-columns: span 3 / -1;
            }

            .c-shared-btn-square {
                grid-column: var(--btn-columns);
            }
        }
        &__card {
            @include pre-animation(-100px, -100px); // animation
        }
    }

    &__content {
        @include pre-animation(0px, 100px); // animation
        @media #{md("md")} {
            margin-left: var(--grid-gutter-minus);
        }

        @media #{md("sm", "max")} {
            grid-row: 2;
        }

        h2 {
            @include t2;

            &::v-deep strong {
                color: var(--color-malibu);
            }
        }

        &__desc {
            margin-top: var(--grid-gutter);
        }
    }

    &__accordions {
        counter-reset: accordions;
        &__item {
            @include pre-animation(100px, 0px); // animation
        }
    }
}
