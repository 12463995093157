
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                





































































.c-app-nav-list {
    --nav-list-width: 100%;
    --nav-list-direction: column;
    --nav-list-height: 100%;
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    width: var(--nav-list-width);
    height: var(--nav-list-height);

    @media #{md("sm")} {
        --nav-list-direction: row;
        --nav-list-width: auto;
        --nav-list-height: auto;
    }

    &__inner {
        --nav-list-width: 100%;
        width: var(--nav-list-width);

        @media #{md("sm")} {
            --nav-list-width: auto;
        }

        &__list {
            --nav-list-gap: var(--grid-gutter);
            @include reset-list;
            display: flex;
            flex-direction: var(--nav-list-direction);
            padding-right: var(--nav-list-gap);
            text-align: center;

            .c-app-nav-desktop & {
                --nav-list-gap: var(--grid-gutter-2X);
            }

            @media #{md("sm")} {
                --nav-list-gap: var(--grid-gutter-half);
            }

            @media #{md("sm", "max")} {
                padding: var(--nav-list-gap) 0;
            }
        }
    }
}
