
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                





















































ul {
    --arrow-justify-content: flex-start;
    --arrows-position: relative;
    --arrows-row: 1/1;

    @include reset-list;
    align-self: flex-start;
    display: flex;
    justify-content: space-between;
    padding-top: var(--grid-gutter);

    @media #{md("sm")} {
        --arrow-justify-content: flex-end;
        --arrows-row: 2/2;
    }

    li {
        --translateX: 0px;
        --btn-padding: var(--grid-gutter-half);
        display: flex;
        justify-content: flex-end;
        width: calc((100% - var(--grid-gutter)) * 0.5);

        button {
            padding: var(--btn-padding);
            cursor: pointer;

            &::v-deep .o-icon {
                --svg-width: 2.2rem;
                @include transition(0.3s ease transform);
            }

            &:hover {
                --translateX: 0.5rem;
            }

            &[disabled] {
                opacity: 0.2;
                pointer-events: none;
            }
        }

        &:nth-child(1) {
            button {
                margin-right: calc(var(--btn-padding) * -1);
            }
            &::v-deep .o-icon {
                @include transform(rotate(180deg) translateX(var(--translateX)));
            }
        }

        &:nth-child(2) {
            justify-content: flex-start;

            button {
                margin-left: calc(var(--btn-padding) * -1);
            }

            &::v-deep .o-icon {
                @include transform(translateX(var(--translateX)));
            }
        }
    }
}
