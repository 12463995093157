@import "./breakpoints";

@mixin bs-grid {
    display: grid;
    grid-template-rows: repeat(var(--#{$prefix}rows, 1), 1fr);
    grid-template-columns: repeat(
        var(--#{$prefix}columns, #{$grid-columns}),
        1fr
    );
    column-gap: var(--#{$prefix}col-gap, #{$grid-gutter-width});
    row-gap: var(--#{$prefix}row-gap, #{$grid-gutter-width});

    @include make-cssgrid();
}

// from https://github.com/twbs/bootstrap/blob/main/scss/_grid.scss

@mixin make-row($gutter: $grid-gutter-width) {
    --#{$prefix}gutter-x: #{$gutter};
    --#{$prefix}gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    // TODO: Revisit calc order after https://github.com/react-bootstrap/react-bootstrap/issues/6039 is fixed
    margin-top: calc(
        -1 * var(--#{$prefix}gutter-y)
    ); // stylelint-disable-line function-disallowed-list
    margin-right: calc(
        -0.5 * var(--#{$prefix}gutter-x)
    ); // stylelint-disable-line function-disallowed-list
    margin-left: calc(
        -0.5 * var(--#{$prefix}gutter-x)
    ); // stylelint-disable-line function-disallowed-list
}

@mixin make-col-ready() {
    // Add box sizing if only the grid is loaded
    box-sizing: if(
        variable-exists(include-column-box-sizing) and
            $include-column-box-sizing,
        border-box,
        null
    );
    // Prevent columns from becoming too narrow when at smaller grid tiers by
    // always setting `width: 100%;`. This works because we set the width
    // later on to override this initial width.
    flex-shrink: 0;
    width: 100%;
    max-width: 100%; // Prevent `.col-auto`, `.col` (& responsive variants) from breaking out the grid
    padding-right: calc(
        var(--#{$prefix}gutter-x) * 0.5
    ); // stylelint-disable-line function-disallowed-list
    padding-left: calc(
        var(--#{$prefix}gutter-x) * 0.5
    ); // stylelint-disable-line function-disallowed-list
    margin-top: var(--#{$prefix}gutter-y);
}

@mixin make-col($size: false, $columns: $grid-columns) {
    @if $size {
        flex: 0 0 auto;
        width: percentage(divide($size, $columns));
    } @else {
        flex: 1 1 0;
        max-width: 100%;
    }
}

@mixin make-col-auto() {
    flex: 0 0 auto;
    width: auto;
}

@mixin make-col-offset($size, $columns: $grid-columns) {
    $num: divide($size, $columns);
    margin-left: if($num == 0, 0, percentage($num));
}

// Row columns
//
// Specify on a parent element(e.g., .row) to force immediate children into NN
// numberof columns. Supports wrapping to new lines, but does not do a Masonry
// style grid.
@mixin row-cols($count) {
    > * {
        flex: 0 0 auto;
        width: divide(100%, $count);
    }
}

@mixin make-cssgrid($columns: $grid-columns, $breakpoints: $grid-breakpoints) {
    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        @include breakpoint($breakpoint, $breakpoints) {
            @if $columns > 0 {
                // TODO: fix bug why @ don't responsive properly
                @for $i from 1 through $columns {
                    .col-#{$i}#{$infix} {
                        grid-column: auto / span $i;
                    }
                }

                // Start with `1` because `0` is and invalid value.
                // Ends with `$columns - 1` because offsetting by the width of an entire row isn't possible.
                @for $i from 1 through ($columns - 1) {
                    .start-#{$i}#{$infix} {
                        grid-column-start: $i;
                    }

                    .end-#{$i}#{$infix} {
                        grid-column-end: $i * -1;
                    }
                }
            }
        }
    }
}

// took from https://github.com/twbs/bootstrap/blob/main/scss/mixins/_grid.scss
@if $enable-grid-classes {
    .bs-row {
        @include make-row();

        > * {
            @include make-col-ready();
        }
    }
}

@if $enable-cssgrid {
    .bs-grid {
        @include bs-grid;
        width: 100%;
        max-width: $grid-width;
        margin-right: auto;
        margin-left: auto;
    }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
    // @include make-grid-columns();
}
