
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                







































.b-step-card {
    --action-card-column: auto / span 6;
    --action-card-column-start: 1;
    --action-card-row-start: 1;
    --action-card-ratio: 1/1.3;
    --action-card-opacity: 0;
    --action-card-translate-y: 100px;

    @include shadow(inset 0px 0px 0px 1px var(--color-concrete));

    // animation

    margin-top: var(
        --action-card-translate-y
    ); // margin top instead of translate to avoid weird z index issue after animation
    opacity: var(--action-card-opacity);

    counter-increment: steps;
    grid-column: var(--action-card-column);
    grid-column-start: var(--action-card-column-start);
    grid-row-start: var(--action-card-row-start);
    display: flex;
    position: relative;
    aspect-ratio: var(--action-card-ratio);
    background-color: var(--color-light);

    cursor: pointer;

    @media #{md("xs")} {
        --action-card-column: auto / span 7;
        --action-card-ratio: 1/1.25;
    }

    @media #{md("lg")} {
        --action-card-ratio: 1/1;
    }

    @media #{md("xs", "max")} {
        &:nth-child(odd) {
            margin-left: var(--grid-gutter-minus);
        }
        &:nth-child(even) {
            margin-right: var(--grid-gutter-minus);
        }
    }

    &:nth-child(5n + 2) {
        --action-card-row-start: 2;
        @media #{md("xs")} {
            --action-card-column-start: 6;
        }
        @media #{md("md")} {
            --action-card-column-start: 7;
        }
    }
    &:nth-child(5n + 3) {
        --action-card-row-start: 3;
        @media #{md("xs")} {
            --action-card-column-start: 3;
        }
    }
    &:nth-child(5n + 4) {
        --action-card-row-start: 4;
    }
    &:nth-child(5n + 5) {
        --action-card-row-start: 5;
        @media #{md("xs")} {
            --action-card-column-start: 6;
        }
    }

    &:hover {
        --cross-rotate: 180deg;
        --cross-rotate-line: 0deg;

        &::v-deep .b-steps-card-hover {
            --action-card-height: 0%;
        }
        &::v-deep .b-steps-content--full {
            --opacity-content: 1;
            --transform-animation: 0rem;
            --delay-animation: 0.1s;
        }
    }

    &__main {
        display: flex;
        flex: 1;
    }
}
