
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                








































.c-app-footer-top-contact-form {
    // @include pre-animation(0px, 100px);
    --margin-right: 0rem;
    display: flex;
    flex-direction: column;
    color: var(--color-dark);

    h3 {
        @include pre-animation(0px, 30px);
        @include t3;
        font-size: 3rem !important;
        margin-bottom: var(--grid-gutter);
    }
}
