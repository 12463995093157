
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                



























































.c-app-nav-mobile-contact {
    --scale-line: 0;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: var(--grid-gutter-1-5X) 0px;

    &:before {
        @include full-screen-dom(absolute);
        left: var(--grid-gutter-2X);
        right: var(--grid-gutter-2X);
        content: " ";
        height: 1px;
        background-color: var(--color-concrete);
        transform: scaleX(var(--scale-line));
        transform-origin: left center;
    }

    &__list {
        --mobile-contact-spacing: var(--grid-gutter);
        @include t-content(var(--mobile-contact-spacing));

        li {
            display: flex;
            justify-content: center;
            align-items: center;

            @include transform(translateY(5rem));
            opacity: 0;
        }

        &__contact {
            flex-direction: column;
            font-size: fs("large");

            a {
                padding: 0px;
                margin-top: calc(var(--mobile-contact-spacing) * 0.35);
            }
        }

        .c-btn--underline {
            margin-top: -0.5em;
            margin-bottom: -0.5em;
        }
    }
}
