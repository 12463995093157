
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                




































































.l-header {
    $self: &;
    $transition-header: background 0.5s ease, transform 0.5s ease;
    --background-color-header: var(--color-light);
    --translate-header-y: 0%;
    --background-color-header: none;
    @include transform(translateY(var(--translate-header-y)));
    @include transition($transition-header);
    position: fixed;
    width: 100%;
    background: var(--background-color-header);
    z-index: 99;

    @media #{md("sm")} {
        --translate-header-y: -100%;
    }

    &--plain {
        --background-color-header: var(--color-light);
        @include shadow(inset 0px -1px 0px 0px var(--color-concrete));
    }

    &--visible {
        --translate-header-y: 0%;
    }

    &__inner {
        --header-justify: space-between;
        --header-height: 11rem;
        display: flex;
        justify-content: var(--header-justify);
        height: var(--header-height);
        transition: height 0.5s $in-out-quad;

        #{$self}--plain:not(#{$self}--menu-mobile-open) & {
            --header-height: 7rem;
        }

        @media #{md("sm")} {
            --header-justify: flex-start;
            --header-height: auto;
        }
    }
}
