
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                












































.c-navigation-link {
    --nav-list-gap: var(--grid-gutter);
    --color-btn: var(--color-gray-chateau);
    padding-right: var(--nav-list-gap);

    .c-app-nav-desktop & {
        --nav-list-gap: var(--grid-gutter-2X);
    }

    @media #{md("sm")} {
        --nav-list-gap: var(--grid-gutter-half);
    }

    @media #{md("sm", "max")} {
        padding: var(--nav-list-gap) 0;
    }

    &__link {
        --line-display: none;
        --color-line: var(--color-malibu);
        --navigation-link-top: -0.8em;
        $padding: 2em;
        --padding-nav-list: 2.6rem;
        --background-position: 100%;

        @include disabler;
        @include transform(translateY(5rem));
        @include transition(background 0.3s ease);
        position: relative;
        padding: var(--padding-nav-list) 0 calc(var(--padding-nav-list) - 0.7rem);
        display: inline-block;
        line-height: 1;
        cursor: pointer;

        background-image: linear-gradient(90deg, var(--color-dark) 50%, var(--color-btn) 50%);
        background-clip: border-box;
        background-size: 200% 100px;
        background-position: var(--background-position);
        background-clip: text;
        -webkit-background-clip: text;
        text-fill-color: transparent;
        -webkit-text-fill-color: transparent;

        &.is-active {
            --line-display: block;
            --background-position: 0%;
        }

        &:hover {
            --background-position: 0%;
        }

        &:after {
            display: var(--line-display);
            content: " ";
            position: absolute;
            height: 0.4rem;
            width: 100%;
            background-color: var(--color-line);
            left: 0;
            top: 0;
        }

        .c-app-nav-mobile & {
            --padding-nav-list: 1.6rem;
            font-size: fs("xlarge");
            padding-bottom: 0;
        }
    }
}
