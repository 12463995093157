
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                














































































.l-main {
    margin-top: -1px; //1px from the hero https://mambomambo-team.atlassian.net/browse/QUAD-46
}
