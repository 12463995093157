
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                



































































































































































































































































































































































































































.l-elements {
    @include wrap;
    --elements-grid-gap: var(--grid-gutter);
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--elements-grid-gap);
    z-index: 5;

    @media #{md("sm")} {
        --elements-grid-gap: var(--grid-gutter-2X);
    }
    @media #{md("lg")} {
        --elements-grid-gap: var(--grid-gutter-3X);
    }
    @media #{md("xxl")} {
        --elements-grid-gap: var(--grid-gutter-4X);
    }

    &__list {
        @media #{md("xs", "max")} {
            margin-bottom: var(--grid-gutter);
        }
    }

    .c-card {
        @include pre-animation(0px, 100px); // animation
    }
}
