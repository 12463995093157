
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                





































.c-shapes-square {
    --background-color: var(--color-light);
    --width-shape-square: 150px;
    --min-width: auto;
    --min-height: auto;
    //  padding-bottom: 100%;// aspect-ratio: 1/1; https://mambomambo-team.atlassian.net/browse/QUAD-252

    position: absolute;
    top: 0;
    left: 0;
    width: var(--width-shape-square);
    height: var(--width-shape-square);
    z-index: 2;
    background: var(--background-color);

    min-width: var(--min-width);
    min-height: var(--min-height);

    &--responsive {
        --width-shape-square: 80px;
        @media #{md("sm")} {
            --width-shape-square: 120px;
        }
        @media #{md("lg")} {
            --width-shape-square: 150px;
        }
    }
    &__icon {
        width: 100%;
        height: 100%;
    }
    &::v-deep svg {
        --svg-width: 100%;
        width: 100%;
    }
}
