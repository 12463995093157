
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                


































































































.b-scrollhack {
    --scroll-hack-min-height: auto;
    &__inner {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: var(--scroll-hack-min-height);
        @media #{md("sm")} {
            --scroll-hack-min-height: 35rem;
        }

        &__trigger {
            // there is a bug if the trigger is located on the center when the user scroll back.
            position: absolute;
            width: 1rem;
            height: 1rem;
            top: calc(50% - 50vh);
            z-index: 999;
            // background-color: blue; // debug
        }
    }
}
