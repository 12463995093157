
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                


























































.b-image {
    &.-zoom {
        > div {
            cursor: zoom-in;
        }
    }
}
