
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                











































.o-icon {
    display: inline-block;
    vertical-align: middle;

    svg {
        display: block;
        width: var(--svg-width, 1em);
        height: calc(var(--svg-width, 1em) * var(--svg-ratio, 1));
        fill: currentColor;
    }
}

/*----------  SVG Sizes  ----------*/

// // Socials
// .svg-instagram {
//    --svg-width: 1em;
//    --svg-ratio: 16/16;
// }

.svg-logo-gb {
    --svg-width: 1em;
    --svg-ratio: 82/433;
}

.svg-logo-gb-icon {
    --svg-width: 1em;
    --svg-ratio: 76/107;
}

.svg-logo-a {
    --svg-width: 1em;
    --svg-ratio: 39.5/107;
}

.svg-logo-eh {
    --svg-width: 1em;
    --svg-ratio: 49/170;
}

.svg-logo-se {
    --svg-width: 1em;
    --svg-ratio: 46/149;
}
