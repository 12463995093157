
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                







































































.c-hero-bottom {
    &__grid {
        --bs-row-gap: var(--grid-gutter);
        align-items: center;

        &__logo {
            @media #{md("xs", "max")} {
                padding-top: var(--grid-gutter-1-5X);
            }

            svg {
                display: block;
                width: 100%;
                height: auto;
                aspect-ratio: 1 / 0.253;
                fill: var(--color-dark);
            }

            .logo-letter {
                @include transform(translateY(100%));
            }
            .logo-square {
                @include transform(scale(1, 0));
            }
        }

        &__arrow {
            --svg-width: 7px;
            --svg-ratio: 12.571;
            @include pre-animation(0px, -100px);
            align-self: center;

            &:only-child {
                grid-column: 1;
                justify-self: start;
            }

            @media #{md("sm")} {
                justify-self: end;
                margin-right: -4px;
            }
        }
    }
}
