
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                























































































































.c-accordion {
    --color-text: var(--color-gray-chateau);
    color: var(--color-text);
    padding: var(--grid-gutter-1-25X) 0;

    &:not(:last-child) {
        border-bottom: 1px solid var(--color-concrete);
    }

    &__header {
        cursor: pointer;

        &:before {
            @include fontHeading;
            counter-increment: accordions;
            content: counter(accordions, decimal-leading-zero) ".";
            display: block;
            color: var(--color-gray-chateau);
            font-size: fs("xlarge");
            margin-bottom: 0.8rem;
        }
    }

    &__title {
        @include transition(color 0.5s ease);
        max-width: 51rem;
        padding-right: var(--grid-gutter-3X);
    }

    .c-cross {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__container {
        height: 0;
        opacity: 0;
        overflow: hidden;
    }

    &__content {
        @include transition(color 0.5s ease);
        max-width: 51rem;
        padding-top: 2rem;
        padding-right: var(--grid-gutter-3X);
        pointer-events: none;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    &.is-open {
        --cross-rotate: 180deg;
        --cross-rotate-line: 0deg;
        --color-text: var(--color-dark);

        .c-accordion__content {
            pointer-events: initial;
        }
    }

    &.-upside-down {
        display: flex;
        flex-direction: column;

        .c-accordion__header {
            order: 2;
        }

        .c-accordion__container {
            order: 1;
        }
    }
}
