
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                

















































































.c-app-nav-mobile {
    --background-color-header: var(--color-light);
    --nav-position: absolute;
    --min-height: var(--vh);
    --display-nav-mobile: flex;

    @include full-screen-dom(absolute);
    background: var(--background-color-header);

    height: var(--min-height);
    overflow: auto;

    margin-left: calc(0px - var(--grid-gutter-half));
    margin-right: calc(0px - var(--grid-gutter-half));

    display: var(--display-nav-mobile);
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;

    @media #{md("sm")} {
        --display-nav-mobile: none;
    }

    &__inner {
        --nav-direction: column;
        display: var(--display-nav-mobile);
        flex-direction: var(--nav-direction);
        flex: 1;
        flex-grow: 1;
        flex-shrink: 0;

        @media #{md("sm", "max")} {
            &:before {
                content: " ";
                display: block;
                height: 7rem;
                width: 100%;
                flex-shrink: 0;
            }
        }
    }
}

.mobile-navigation-enter-active,
.mobile-navigation-leave-active {
    $transition-burger: transform 0.5s $in-out-cubic;
    @include transition($transition-burger);
}

.mobile-navigation-enter,
.mobile-navigation-leave-to {
    @include transform(translateX(-100%));
}
