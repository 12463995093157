
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                




































































.l-builder {
    --section-spacer-top: 10rem;
    --section-spacer-bottom: 10rem;
    --block-spacer: 10rem;

    // Sections
    .-s {
        &:not(:first-of-type) {
            padding-top: var(--section-spacer-top);
        }
        &:not(:last-of-type) {
            padding-bottom: var(--section-spacer-bottom);
        }

        // Custom spacings on homepage
        &#section-approche {
            --section-spacer-top: 0;
            --section-spacer-bottom: 8.5rem;
        }
        &#section-distinction {
            --section-spacer-top: 0;
            @media #{md("lg", "max")} {
                --section-spacer-bottom: 0;
            }
        }
        &#section-reflexions {
            --section-spacer-top: 8.5rem;
            --section-spacer-bottom: 0;
        }
        &#section-references {
            @media #{md("lg")} {
                --section-spacer-top: 3rem;
            }
        }
    }

    // Blocks
    .-b {
        &:not(:first-of-type) {
            margin-top: var(--block-spacer);
        }

        &.block-tagline + .block-content {
            --block-spacer: 3rem;
        }
    }
}
