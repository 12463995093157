
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                




















































































































.b-steps {
    display: flex;
    flex-direction: column;

    &__inner {
        &__intro {
            --block-action-h1-max-width: 770px;
            --block-action-p-max-width: 500px;
            h1 {
                @include t1;
                @include b-for-blue;
                margin-bottom: var(--grid-gutter-2X);
                max-width: var(--block-action-h1-max-width);
            }
            p {
                @include fontCopy;
                max-width: var(--block-action-p-max-width);
            }
            &__copy {
                @include pre-animation(-100px, 0px); // animation
            }
        }
    }
}
