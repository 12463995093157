
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                































































































































































































.c-hero {
    --v-spacing: 11rem;

    &.c-hero--no-image {
        row-gap: 0;
    }

    @media #{md("sm")} {
        --v-spacing: var(--grid-gutter-6X);
    }

    @media #{md("md")} {
        align-items: end;
    }

    .js-hero-stagger {
        @include pre-animation(-100px, 0px);
    }

    &__title {
        @include t1;
    }

    &__copy {
        padding-top: var(--v-spacing);

        @media #{md("xs", "max")} {
            --v-spacing: 0;
            order: 2;
        }

        @media #{md("md")} {
            margin-right: var(--grid-gutter-minus);
        }

        &__author {
            margin-top: var(--grid-gutter);
        }

        &__excerpt {
            margin-top: var(--grid-gutter-2X);
        }

        &__btn-share {
            @include pre-animation(0px, 100px);
            margin-top: var(--grid-gutter);

            @media #{md("md")} {
                margin-top: var(--grid-gutter-4X);
            }
        }
    }

    &__visual {
        padding-top: var(--v-spacing);
        position: static;

        @media #{md("xs", "max")} {
            order: 1;
        }

        @media #{md("lg", "max")} {
            margin-right: calc(var(--wrap-spacer) * -1);
        }

        @media #{md("lg")} {
            margin-left: var(--grid-gutter-minus);
        }

        .c-hero-visual-blue-square {
            --width-square: 58rem;
            width: clamp(27.5rem, var(--width-square), 60vw);
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;

            @media #{md("sm")} {
                top: 6.5rem;
            }
            @media #{md("lg")} {
                top: 0;
            }
        }

        .c-hero-visual-square {
            // Fake first load
            --intersection-y: 100%;

            .c-shapes-square--responsive {
                --width-shape-square: 0;
            }
        }

        &__blue-square {
            @include transform(translate(-10%, -20%));
        }

        &__img {
            @include transform(translate(10%, -20%));
            z-index: 2;

            .o-asset {
                width: 100%;
                aspect-ratio: 1/1.3;

                &__img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    h1 {
        @include t1;
    }
}
