
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                










































.c-card-content-date {
    --content-date-left: var(--grid-gutter-half);
    --line-display: none;
    @include reset-list;
    display: flex;
    flex-direction: row;

    &--full {
        --content-date-left: calc(2rem + var(--grid-gutter));
        --line-display: flex;
    }

    li {
        position: relative;
        &:nth-child(2) {
            padding-left: var(--content-date-left);
            &:before {
                content: " ";
                display: var(--line-display);
                position: absolute;
                top: 25%;
                left: var(--grid-gutter-half);
                height: 1px;
                width: 2rem;
                background-color: var(--color-gray-chateau);
            }
        }
    }

    p {
        color: var(--color-gray-chateau);
        font-size: fs("large");
    }
}
