
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                









.c-hero-visual-blue-square {
    background-color: var(--color-malibu);

    padding-bottom: 100%;// aspect-ratio: 1/1; https://mambomambo-team.atlassian.net/browse/QUAD-252
}
