
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                




























.c-shared-thumbnail {
    @include radius(50%);
    max-width: 6rem;
    overflow: hidden;
}
