
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                

























.b-logo {
    opacity: 0.4;
    filter: grayscale(100%);

    .o-asset {
        --asset-width: 8rem;
        --asset-height: calc(var(--asset-width) * 0.41);
        width: var(--asset-width);
        height: var(--asset-height);

        @media #{md("sm")} {
            --asset-width: 11rem;
        }

        img {
            height: 100%;
            object-fit: contain;
        }
    }
}
