
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                

























































































































































































































.c-slider {
    --slide-width: 100%;
    --slide-gap: var(--grid-gutter);
    --nav-bg: #{$color-light};
    height: auto;

    &.-no-translate {
        --slide-width: 100%;

        .flickity-slider {
            transform: none !important;
        }

        .c-slider__slide {
            &:not(:first-child) {
                top: 0;
                left: 0 !important;
                width: 100%;
            }

            &.is-selected {
                z-index: 1;
            }
        }
    }

    &.-fullheight {
        .flickity-viewport {
            height: var(--slide-height) !important;
        }

        .c-slider__slide {
            height: var(--slide-height);
        }
    }

    &.is-dragging {
        a,
        button {
            pointer-events: none;
        }
    }

    &.-zoom {
        .c-slider__slide {
            cursor: zoom-in;
        }
    }
    @media #{md("sm")} {
        --slide-width: calc(1 / 4 * 100%);
    }

    @media #{md("xl")} {
        --slide-width: calc(1 / 5 * 100%);
    }
}

.c-slider__slide {
    display: block;
    // width: var(--slide-width);
    margin-right: var(--slide-gap);

    user-select: none;
}

.c-slider__side {
    display: flex;
    align-items: center;
    margin-bottom: var(--grid-gutter);

    .c-slider__afterNav {
        position: absolute;
        right: 0;
    }
    .c-slider__beforeNav {
        margin-right: var(--grid-gutter);
    }
}

.c-slider__nav {
    --slider-progress: 0;
    display: flex;
    overflow: hidden;
    border: solid 1px currentColor;

    &.is-disabled {
        pointer-events: none;
    }
    .c-slide__count {
        --slider-btn-width: 5.25em;
        background: var(--nav-bg);
        border-left: solid 1px currentColor;
        border-right: solid 1px currentColor;
        padding: 0.75em 1em;
        text-align: center;
        width: var(--slider-btn-width);
        user-select: none;
    }
}

.c-slider__btn {
    display: flex;
    align-items: center;
    padding: 0.75em 1em;
    cursor: pointer;

    &:before {
        @include pseudo-el;
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 0.2s ease-out;
    }

    &:hover:before {
        transition: transform 0.3s ease-out;
    }

    &.is-disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &.-prev {
        border-top-left-radius: $border-radius-sm;
        border-bottom-left-radius: $border-radius-sm;
        background: var(--nav-bg);
    }

    &.-next {
        border-top-right-radius: $border-radius-sm;
        border-bottom-right-radius: $border-radius-sm;
        background: var(--nav-bg);
    }
}
