
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                
























































.c-app-nav-desktop {
    --display-nav-mobile: none;
    --button-padding: 0rem 0.1rem;

    display: var(--display-nav-mobile);
    height: auto;

    @media #{md("sm")} {
        --display-nav-mobile: flex;
    }

    &__inner {
        --nav-direction: column;

        display: flex;
        flex-direction: var(--nav-direction);

        @media #{md("sm")} {
            --nav-margin-top: 0rem;
        }
    }
}
