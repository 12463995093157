
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                


































































.c-app-footer-top-meet {
    color: var(--color-light);

    &__list {
        @include darkBackgroundTextSelect;
        @include reset-list;
        display: grid;
        gap: var(--grid-gutter);

        li {
            @include pre-animation(100px, 0px);
            a,
            span {
                @include transition(0.5s ease all);
                @include t4;
                text-decoration: none;
                white-space: pre-wrap;
            }

            a {
                &[href^="mailto"] {
                    white-space: nowrap;
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }
    }
}
