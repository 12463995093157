
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                

















































.c-hero-visual-asset {
    --width-asset: 50%;
    --translate-x-asset: 0;
    --translate-y-asset: 0;

    width: var(--width-asset);
    position: absolute;
    top: var(--intersection-y);
    left: var(--intersection-x);
    z-index: 1;

    &__img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(var(--translate-x-asset), var(--translate-y-asset));
    }

    // Header types
    &--stairs {
        --width-asset: 61.7%;
        --translate-x-asset: -20%;
        --translate-y-asset: -57.8%;
    }

    &--triangles {
        --width-asset: 88.4%;
        --translate-x-asset: -55.3%;
        --translate-y-asset: -0.3%;
    }

    &--cubes {
        --width-asset: 75.7%;
        --translate-x-asset: -22.3%;
        --translate-y-asset: -3.2%;
    }
    &--columns {
        --width-asset: 75.7%;
        --translate-x-asset: -26%;
        --translate-y-asset: 0%;
    }
    &--rectangles {
        --width-asset: 75.7%;
        --translate-x-asset: -32%;
        --translate-y-asset: 0%;
    }
}
