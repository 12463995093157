/*================================================
=            Positions & dispositions            =
================================================*/
@mixin clearfix {
    &:after {
        content: "";
        clear: both;
        display: block;
    }
}

/*==================================
=            Typography            =
==================================*/

@mixin fontfaces($webfonts, $dir) {
    @each $webfont in $webfonts {
        @font-face {
            font-family: nth($webfont, 1);
            src: url("#{$dir}#{nth($webfont, 2)}.woff2") format("woff2"),
                url("#{$dir}#{nth($webfont, 2)}.woff") format("woff");
            font-weight: #{nth($webfont, 3)};
            font-style: #{nth($webfont, 4)};
            font-display: swap;
        }
    }
}

@mixin reset-list {
    margin: 0;
    padding-left: 0;
    list-style: none;
}

@mixin ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@mixin antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-osx-font-smoothing: antialiased;
}

// Nicely hyphenate long words
// cf. https://justmarkup.com/log/2015/07/31/dealing-with-long-words-in-css/
@mixin hyphenate {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}

/**
 * Responsive typography
 * @author Mike Riethmuller http://codepen.io/MadeByMike/pen/YPJJYv
 * @param  {integer} $min-width: 0            The breakpoint minimum to activate the responsive typography
 * @param  {integer} $max-width: 2560         The breakpoint maximum to activate th responsive typography
 * @param  {integer} $min-font:  12           The minimum font-size for the element
 * @param  {integer} $max-font:  16           The maximum font-size for the element
 * @return {void}
 */
@mixin responsive-type(
    $min-font: 12,
    $max-font: 16,
    $min-width: 0,
    $max-width: 2560
) {
    // Set min size
    font-size: $min-font * 1px;

    // Adjust size between `$min-width` et `$max-width`
    @media (min-width: #{$min_width}px) and (max-width: #{$max_width}px) {
        font-size: calc(
            #{$min_font}px + (#{$max_font} - #{$min_font}) *
                ((100vw - #{$min_width}px) / (#{$max_width} - #{$min_width}))
        );
    }

    // Set max size
    @media (min-width: #{$max_width}px) {
        font-size: #{$max_font}px;
    }
}

@mixin t-content($margin-between: 1rem) {
    // Old version keep for eventual debug with .c-btn usage
    /*
    > *:nth-child(n + 2) {
        margin-top: $margin-between;

        &.c-btn {
            margin-top: calc(#{$margin-between} * 2);
        }
    }
    */
    display: grid;
    grid-auto-rows: minmax(min-content, max-content);
    gap: var(--content-gap, $margin-between);

    .-actions {
        padding-top: var(
            --content-actions-extra-gap,
            calc(#{$margin-between} * 2)
        );
    }
}

@mixin t1 {
    @include fontHeading;
    @include responsive-type(
        $min-font: 40,
        $max-font: 60,
        $min-width: 480,
        $max-width: 1440
    );
    line-height: 100%;
}

@mixin t2 {
    @include fontHeading;
    @include responsive-type(
        $min-font: 30,
        $max-font: 40,
        $min-width: 480,
        $max-width: 1440
    );
    line-height: 110%;
}

@mixin t3 {
    @include fontHeading;
    @include responsive-type(
        $min-font: 20,
        $max-font: 30,
        $min-width: 480,
        $max-width: 1440
    );
    line-height: 110%;
}

@mixin t4 {
    @include fontHeading;
    @include responsive-type(
        $min-font: 20,
        $max-font: 20,
        $min-width: 480,
        $max-width: 1440
    );
    line-height: 110%;
}

@mixin fontHeading {
    font-family: ff("heading");
}

@mixin fontCopy {
    font-family: ff("body");
    font-size: fs("large");
}

@mixin fontSmallCopy {
    font-family: ff("body");
    font-size: fs("regular");
}

@mixin underline(
    $line-display: none,
    $color-line: $color-light,
    $color-line-2: $color-black,
    $padding: 2em,
    $background-position: 100%,
    $margin-bottom: 0.4em
) {
    &:after {
        @include transition(0.3s ease background);
        display: $line-display;
        content: " ";
        position: absolute;
        height: 1px;
        // background-color: $color-line;
        left: $padding;
        bottom: $margin-bottom;
        right: $padding;
        background-image: linear-gradient(
            90deg,
            $color-line-2 50%,
            $color-line 50%
        );
        background-size: 200% 100px;
        background-position: $background-position;
    }
}

@mixin t-cms {
    // Titles
    h1,
    h2,
    h3,
    h4 {
        margin: 1.45em 0 0.7em;
        line-height: 1.2;
        font-weight: 700;
    }

    h1 {
        @include t1;
    }
    h2 {
        @include t2;
    }
    h3 {
        @include t3;
    }
    h4 {
        @include t4;
    }

    // Text
    p,
    ul,
    ol,
    blockquote {
        margin-bottom: $line-height * 1em;
    }

    p {
        font-size: fs("large");
    }

    ul {
        @include reset-list;
    }

    ul li {
        padding-left: var(--grid-gutter-half);
        &:before {
            content: "\25AA";
            color: #9da4b3;
            left: -5px;
            margin-left: -6px;
        }
        &:marker {
            display: none;
        }
    }

    a {
        &:hover {
        }
    }

    // Medias
    figure {
        @media #{md("xs", "max")} {
            margin-left: var(--grid-gutter-minus);
        }
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    // Layout
    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

/*=======================================
=            Pseudo elements            =
=======================================*/

@mixin pseudo-el(
    $content: "",
    $display: block,
    $width: 100%,
    $height: 100%,
    $bg: none
) {
    content: $content;
    display: $display;
    width: $width;
    height: $height;

    @if type-of($bg) == color {
        background-color: $bg;
    } @else {
        background: $bg;
    }
}

/*=============================
=            Image            =
=============================*/

@mixin img($width: 100%) {
    display: block;
    width: $width;
    height: auto;
}

/*=================================
=            Triangles            =
=================================*/

@mixin triangle(
    $direction,
    $triangle-width,
    $triangle-height,
    $triangle-color
) {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;

    @if $direction == up {
        border-color: transparent transparent $triangle-color;
        border-width: 0 $triangle-width / 2 $triangle-height;
    } @else if $direction == right {
        border-color: transparent transparent transparent $triangle-color;
        border-width: $triangle-height /
            2
            0
            $triangle-height /
            2
            $triangle-width;
    } @else if $direction == down {
        border-color: $triangle-color transparent transparent;
        border-width: $triangle-height $triangle-width / 2 0;
    } @else if $direction == left {
        border-color: transparent $triangle-color transparent transparent;
        border-width: $triangle-height /
            2
            $triangle-width
            $triangle-height /
            2
            0;
    } @else {
        @error "The direction `#{$direction}` is not allowed, choose between up, right, down, left. Property omitted";
    }
}

/*======================================
=            SVG Responsive            =
======================================*/

/*
Utilitaire pour le padding hack qui permet d'avoir des fichiers `*.svg` responsive.
Plus d'infos ici : http://tympanus.net/codrops/2014/08/19/making-svgs-responsive-with-css/
*/

@mixin padding-hack($svg-width, $svg-height, $container-width) {
    width: $container-width;
    height: 0;
    padding-top: strip-units(($svg-height / $svg-width) * $container-width) * 1%;
}

/*=================================
=            Gradients            =
=================================*/

/**
 * Mixin printing a linear-gradient
 * as well as a plain color fallback
 * and the `-webkit-` prefixed declaration
 * @param {String | List | Angle} $direction - Linear gradient direction
 * @param {Arglist} $color-stops - List of color-stops composing the gradient
 */
@mixin linear-gradient($direction, $color-stops...) {
    @if is-direction($direction) == false {
        $color-stops: ($direction, $color-stops);
        $direction: 180deg;
    }

    background: nth(nth($color-stops, 1), 1);
    background: linear-gradient($direction, $color-stops);
}

/*======================================
=            Viewport width            =
======================================*/

@mixin center-viewport($width: 100vw) {
    left: calc(50% - #{$width});
    width: $width;
}

/*========================================
=            Grid breakpoints            =
=        For property dependecies        =
========================================*/

@mixin grid-gutters($property, $ratio: 1) {
    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);
        $grid-gutter: map-get($grid-gutters, $key);

        @media #{md(#{$key})} {
            #{$property}: $grid-gutter * $ratio;
        }
    }
}

/*========================================
=            Responsive class            =
========================================*/

@mixin responsive-class($max-width: true) {
    @content;

    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);

        @media #{md($key)} {
            &\@#{$key} {
                @content;
            }
        }

        @if $max-width == true {
            @media #{md($key, "max")} {
                &\@#{$key}-max {
                    @content;
                }
            }
        }
    }
}

/*======================================
=            Hide scrollbar            =
======================================*/

@mixin hide-scrollbar {
    // Hide scrollbar
    scrollbar-width: none;
    scrollbar-height: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background-color: transparent;
    }
}

/*=========================================
=            Default animation            =
=========================================*/

@mixin anim-reveal-hide {
    opacity: 0;
    transform: translate(0, 2rem);
    transition: transform var(--a-out-duration, 0.4s)
            var(--a-out-ease, #{$in-sine}) var(--a-out-delay, 0s),
        opacity var(--a-out-duration, 0.4s) var(--a-out-ease, #{$in-sine})
            calc(var(--a-out-duration, 0.4s) + var(--a-out-delay, 0s));
    // will-change: opacity, transform;
}

@mixin anim-reveal-show {
    opacity: 1;
    transform: translate(0);
    transition: transform var(--a-in-duration, 0.4s)
            var(--a-in-ease, #{$out-quad}) var(--a-in-delay, 0s),
        opacity var(--a-in-duration, 0.4s) var(--a-in-ease, #{$out-quad})
            var(--a-in-delay, 0s);
}

@mixin anim-reveal-default {
    &.js-reveal {
        @include anim-reveal-hide;

        &.is-visible {
            @include anim-reveal-show;
        }
    }
}

/*=========================================
=            HELPERS
=========================================*/

@mixin fit_cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin transition-delay($transition) {
    -webkit-transition-delay: $transition;
    -moz-transition-delay: $transition;
    -o-transition-delay: $transition;
    transition-delay: $transition;
}

@mixin transform($transform) {
    -webkit-transform: $transform;
    -moz-transform: $transform;
    -o-transform: $transform;
    transform: $transform;
}

@mixin shadow($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;
}
@mixin textShadow($shadow) {
    text-shadow: $shadow;
}

@mixin rotate($rotate) {
    -ms-transform: rotate($rotate); /* IE 9 */
    -webkit-transform: rotate($rotate); /* Safari 3-8 */
    transform: rotate($rotate);
}

@mixin radius($rad) {
    -webkit-border-radius: $rad; /* Safari 3-4, iOS 1-3.2, Android 1.6- */
    -moz-border-radius: $rad; /* Firefox 1-3.6 */
    border-radius: $rad; /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */

    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}

@mixin blur($spread) {
    filter: blur($spread);
    -o-filter: blur($spread);
    -ms-filter: blur($spread);
    -moz-filter: blur($spread);
    -webkit-filter: blur($spread);
}

@mixin b-for-blue {
    // enable use to highlight some part of the text
    color: var(--color-dark);

    --background-position: 100%;

    &::v-deep strong {
        background-image: linear-gradient(
            90deg,
            var(--color-malibu) 50%,
            var(--color-dark) 50%
        );
        background-clip: border-box;
        background-size: 200% 100px;
        background-position: var(--background-position);
        background-clip: text;
        -webkit-background-clip: text;
        text-fill-color: transparent;
        -webkit-text-fill-color: transparent;

        .is-visible & {
            --background-position: 0%;
            @include transition(0.8s ease all 0.5s);

            color: var(--color-malibu);
        }
    }
}

@mixin shared-grid() {
    --column-grid: 6;
    display: grid;
    gap: var(--grid-gutter-half);
    // row-gap: var(--grid-gutter-double);
    grid-template-columns: repeat(var(--column-grid), 1fr);

    @media #{md("xs")} {
        --column-grid: 12;
    }
    @media #{md("md")} {
        --column-grid: 16;
    }
}

@mixin grid {
    width: $grid-width;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@mixin wrap {
    --wrap-spacer: var(--grid-gutter);
    width: calc(#{$grid-width} + var(--wrap-spacer) * 2);
    max-width: 100%;
    padding-right: var(--wrap-spacer);
    padding-left: var(--wrap-spacer);
    margin-right: auto;
    margin-left: auto;

    @media #{md("md")} {
        --wrap-spacer: var(--grid-gutter-4X);
    }
}

@mixin design-system-list {
    @include reset-list;
    display: flex;
    flex-direction: column;
    gap: var(--grid-gutter);
}

@mixin disabler {
    &[aria-disabled="true"] {
        pointer-events: none;
        text-decoration: none;
        cursor: not-allowed;
    }
}

@mixin full-screen-dom(
    $position: fixed,
    $top: 0,
    $right: 0,
    $bottom: 0,
    $left: 0
) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin darkBackgroundTextSelect {
    // color: $selection-bg;
    // background: $selection-color;
    ::selection {
        text-shadow: none;
        color: $selection-bg;
        background: $selection-color;
    }
}

@mixin pre-animation($translateX, $translateY) {
    @include transform(translate($translateX, $translateY));
    opacity: 0;
}
