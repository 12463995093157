
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                
























































.c-hero-visual-square {
    --top-shape-square: auto;
    --bottom-shape-square: 0;
    --left-shape-square: auto;
    --right-shape-square: 0;
    position: absolute;
    bottom: calc(100% - var(--intersection-y));
    right: calc(100% - var(--intersection-x));

    &__shape {
        top: var(--top-shape-square);
        bottom: var(--bottom-shape-square);
        left: var(--left-shape-square);
        right: var(--right-shape-square);
    }

    // Header types
    &--triangles {
        --left-shape-square: 0;
        --right-shape-square: auto;
    }
}
