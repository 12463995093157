
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                





















































































































.b-scrollhack-main-square-icon {
    --opacity: 0;
    @include transition(0.5s ease opacity);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    position: relative;
    z-index: 999;
    height: 100%;
    opacity: var(--opacity);
    &--visible {
        --opacity: 1;
    }
    &__lottie {
        position: absolute;
        width: 100%;
        height: 100%;
        max-width: 4rem;
    }
}
