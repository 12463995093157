
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                

















































































































































































































































.o-at {
    display: block;

    &.-chars {
        .o-at__l {
            margin-top: 0;
            padding-top: 0;
        }
    }

    html.reduced-motion & {
        &.t-cms > *,
        .o-at__w {
            transition: none !important;
        }
    }
}
.o-at__l {
    margin-top: -0.2em;
    padding-top: 0.2em;
    margin-bottom: -0.4em;
    padding-bottom: 0.2em;
    overflow: hidden;

    // display the text above the decorative image
    z-index: 3;
}

.-last-line-above {
    .o-at__l {
        z-index: 1;
        &:last-child {
            z-index: 3;
        }
    }
}

.-first-last-line-below {
    .o-at__l {
        z-index: 3;
        &:first-child,
        &:last-child {
            z-index: 1;
        }
    }
}

.o-at__c,
.o-at__w {
    transform-origin: 0 100%;
    will-change: transform;
}
